<template>
  <div class="MyControlPinBox">
    <el-row>
        <el-col :span="24"   class="titbox">
            <el-col :span="8">
              <span class="title">我的控销</span>
              <span class="ruleBtn"  @click="visible = !visible"><i class="el-icon-warning-outline"></i>品种控销规则说明</span>
            </el-col>
            <div class="searchBox">
      <!-- @click='SearchShowFun' -->
              <div class="search" >
                <el-col :span="16">
                  <el-input
                    v-model="search"
                    placeholder="请输入商品名称"
                    @focus="focus"
                    @blur="blur"
                    @input="searchEvent"
                  >
                  </el-input>
                </el-col>
                <div  @click="searchFun" class="searchBtn">搜索</div>
              </div>
            </div>
        </el-col>
    </el-row>
    <p class="progressBar"></p>
    <div class="controlPinBox">
      <span class="sortMsg">分类：</span>
        <span class="msgBox">已控品种：({{Itemtotal}})</span>
    </div>
    <div class="sortBox" >
        <span class="sortMsg">排序：</span>
        <span class="timeBtn" @click='filterFun()'>
          解控时间  
          <!-- isSort 0正序 1倒序 -->
              <i
              :class="isSort==0?'active el-icon-caret-top  iconTop':'el-icon-caret-top  iconTop'"
              aria-hidden="true"
            ></i>
            <i 
              :class="isSort==1?'active el-icon-caret-bottom iconBottom':'el-icon-caret-bottom iconBottom'"
              aria-hidden="true"
            ></i>
        </span>
    </div>


    <!-- <p class="progressBar"></p> -->
    <div class="content">
        <div class="ItemBox" v-if="ItemArr.length>0"    v-infinite-scroll="load"  infinite-scroll-disabled="disabled">
          <ConshopItem
            v-for="(item, index) in ItemArr"
            :key="index"
            class="goods"
            :info="item"
          ></ConshopItem>
          <div class="clear"></div>
          <p v-if="loading">加载中...</p>
          <p v-if="noMore">没有更多了</p>
        </div>
        <div class="ItemBox" v-else>
          <img src="@/assets/addProduct.png" alt="">
          <div class="btnBox"><span >无控销商品</span></div>
        </div>
    </div>





    <!-- 控销说明 -->
    <el-dialog
    id="TitMsgBox"
      :visible.sync="visible"
      width="30%"
      >
      <ul id="scrollLoadContent">
        <h4 class="BigTitle">控销规则介绍</h4>
        <p class="MidTitle">
          <b>1.</b> 3A医药城智能控销根据高德地图定位进行区域控制；
        </p>
        <p class="MidTitle">
          <b>2.</b> 规则设置后，同区域内按照购买即控销的原则，购买后获取控销权，同区域内其他客户对我控销的品种不可见，也不可以购买；
        </p>
        <p class="MidTitle">
          <b>3.</b> 超过控销周期后商品将会解控，同区域内其他客户将有机会获得控销权，请在规定的时间内复购，即可继续控销；
        </p>
        <p class="MidTitle">
         <b>4</b> 续控或者添加新的控销品时，请及时到购物车结算，结算后才能完成控销权的确认。
        </p>
      </ul>
    </el-dialog>
  </div>
</template>

<script>
import defaultImage from "@/assets/defaultImage.png";
import { getControlPinList } from "api/product.js";
const ConshopItem = () => import("components/index/ConshopItem.vue");
  export default {
    name:"MyControlPin",
    data(){
        return{
          Itemtotal:0,
          ItemArr:[],
          defaultImage:defaultImage,
          isSort:0,
          datas:{},
          search: "", //当前输入框的值
          isFocus: false, //是否聚焦
          loading: false,
          sizeNum:4,
          visible:false,
        }
    },
   
    methods:{
         // 此时实时搜索，调取接口
    searchEvent(){
      this.datas.current=1
      this.datas.name=this.search,
      this.getData(this.datas)
      // this.clearTimer();
      // if (this.search && this.search.length > 0) {
      //   //获取当前延时函数的ID，便于后面clearTimeout清除该ID对应的延迟函数
      //   this.timer = setTimeout(() => {
      //     this.datas.name=this.search,
      //     this.getData(this.datas)
      //   }, 500);
      // }else{
      //   this.datas.name=this.search,
      //     this.getData(this.datas)
      // }
    },
    // clearTimer() {
    //       if (this.timer) {
    //         clearTimeout(this.timer);
    //       }
    // } ,
      focus() {
        this.isFocus = true;
        this.searchEvent()
      },
      blur() {
        var self = this;
        this.searchBoxTimeout = setTimeout(function() {
          self.isFocus = false;
        }, 300);
      },
      searchFun(){
        this.datas.current=1
        this.datas.name=this.search,
        this.getData(this.datas)
      },
      filterFun(){
        if(this.isSort==0){
          this.isSort=1
        }else{
          this.isSort=0
        }
        this.datas.isSort= this.isSort
   
        this.getData(this.datas)

      },
      getData(FilterDatas){
        getControlPinList(FilterDatas).then(res=>{
          console.log(res.data)
            console.log('oftenBuyuyoftenBuy',this.datas.current)
            if(res.data.msg=="暂无承载数据"){
              this.Itemtotal=0

              this.loading = false
              return false
            }else{
              this.Itemtotal=res.data.data.total
            }
            console.log('aaaaaaaa',Math.ceil(this.Itemtotal/this.sizeNum))
            if(this.datas.current<=Math.ceil(this.Itemtotal/this.sizeNum)+1){
              let records=res.data.data.records
              if(this.datas.current==1){
                this.ItemArr=records
              }else{
                records.forEach(item=>{
                  this.ItemArr.push(item)
                })
              }
               
            }
            else{
              this.loading = false
              // this.ItemArr=[]
            }
          })

      },

      load () {
        this.loading = true
        setTimeout(() => {
          this.datas.current += 1
          this.getData(this.datas)
          this.loading = false
        }, 2000)
      }
    },
    computed: {
      noMore () {
        return this.ItemArr.length >= this.Itemtotal
      },
      disabled () {
        return this.loading || this.noMore
      }
    },
   created(){
        
     this.datas.current=1
     this.datas.size=this.sizeNum
     this.datas.isSort= this.isSort
     this.getData(this.datas)
    

    },
     components:{
      ConshopItem,
    }
  }
</script>

<style lang="less" scoped>
@import "~style/index.less";
#TitMsgBox  /deep/.el-dialog__body{
  padding: 0px 25px 10px !important;
}
.BigTitle {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  color: #2e2e32;
  margin-bottom:7px ;
}
.MidTitle {
    text-indent: 26px;
  font-size:14px;
  line-height:26px;
  color: #2e2e32;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  b{
    font-size: 14px;
    font-weight: bold;
  }
}

.clear{
  clear: both;
}

 .titbox{
        border-bottom:none;
        border-left: 2px solid #FF3333;
        .title{
            font-size: 16px;
            line-height:28px;
            padding-left: 13px;
            color:#333333;
            font-weight: 600;
            cursor: pointer;
            &.active{
              color:#FF3333
            }
            &:nth-child(2){
                color: #666;
                 &.active{
                  color:#FF3333
                }

            }
        }
        .ruleBtn{
          cursor: pointer;
          padding-left: 10px;
          color:#FF3333;
        }
    }

.MyControlPinBox {
  
  .progressBar {
    height: 20px;
    margin-left: -20px;
    width: 100%;
    background: #f6f6f6;
    padding: 0 20px;
    margin-top: 20px;
  }
  .fromWraper {
    padding: 20px 0;
    background: #ff3333;
  }

  .searchBox{
    width:40%;
    height:28px;
    float: right;
    .search{
      width:100%;
      float: left;
      cursor: pointer;
            /deep/ .el-input__inner{
              border-radius:0px;
            height:28px;
            line-height:28px;
            background: #fff;
            font-size:16px;
          }
          /deep/.el-input-group__append{
            color: #3D3D3D;
            font-weight: bold;
            // border-color:#ff3333;
            // background: #ff3333;
            color: #fff;
            font-size:16px;
            font-weight: bold;
          } 
    }
    .searchBtn{
      margin-left: 10px;
      border:1px solid #CCCCCC;
      text-align: center;
      height:26px;
      padding: 0 20px;
      display: inline-block;
      line-height: 26px;
      background: #F0F0F0;
        font-weight: 14px;
        color: #666;
        cursor: pointer;
    }
   
  }
}
.controlPinBox{
  width: 100%;
  padding: 20px 0 0 0;
    width: 100%;
    height:30px;
    .sortMsg{
      padding-right: 10px;
      font-size: 16px;
      line-height:30px;
      color: #666666;
      float: left;
    }
    .msgBox{
      font-size:16px;
      line-height:30px;
      height:30px;
      background: #E00B0B;
      font-size: 16px;
      color: #FFFFFF;
      display: inline-block;
      padding: 0 20px;
      cursor: pointer;
    }
}
.sortBox{
  width: 100%;
  padding: 20px 0;
    width: 100%;
    height: 40px;
    .sortMsg{
      padding-right: 10px;
      font-size: 16px;
      line-height: 40px;
      color: #666666;
      float: left;
    }
  .timeBtn{
      cursor: pointer;
      float: left;
      height:40px;
      line-height:40px;
      font-size: 16px;
      // border-radius: 5px;
      // background: #f6f6f6;
      // border: 1px solid #ccc;
      text-align: center;
      position: relative;
      i{
        font-weight: bolder;
        font-size: 16px;
         color: #666666;
         position:absolute;

        &.active{
           color: #ff3333;
        }
        &.iconTop{
            right:-20px;
            top:7px;
        }
        &.iconBottom{
            right: -20px;
            bottom:7px;
        }
      }
      // &:hover{
      //  
      //   font-weight: bold;
      //   color: #333333;
      //   i{
      //     color: #333333;
      //   }
      // }
    }
    
}

  .content {
    position: relative;
      height:1000px;
      overflow-y: scroll;
      overflow-x: hidden;
   .ItemBox{
     background: #fff;
    //  width: calc(100% + 40px);
     width:100% ;
     padding:0px 20px;
     height: auto;
     position: absolute;
     left: -20px;
     top: 0;
      p{text-align: center;}


      .goods:nth-child(4n + 1) {
        margin-left:3px;
      }
     .goods:nth-child(4n + 0) {
        margin-right: 0px;
      }

      img{
          display: block;
          width:30%;
          margin:0 auto;
          margin-top: 40px;
      }

      .btnBox{
          width: 100%;
              span{
                  margin: 0 auto;
                  display:block;
                  margin-top:20px;
                  color: #9e9e9e;
                  font-weight:600;
                  width:100px;
                  height:40px;
                  text-align: center;
                  line-height:40px;
                  font-size:16px;  
              }  
      }


   }
}
::-webkit-scrollbar  
{  
    width:3px;  
}
  
/*定义滚动条轨道 内阴影+圆角*/  
::-webkit-scrollbar-track  
{  
    border-radius:2px;  
    background-color: #f0f0f0; 
} 
  
/*定义滑块 内阴影+圆角*/  
::-webkit-scrollbar-thumb  
{  
    border-radius:2px;  
    -webkit-box-shadow: inset 0 0 6px #fff;  
    background-color: rgba(0,0,0,0.1);
} 
</style>
